// eslint-disable-next-line ember/no-mixins
import BillingItemMixin from 'client-portal/mixins/polymorphic/billing-item';
import Model, { attr } from '@ember-data/model';

export default class ReceiptModel extends Model.extend(BillingItemMixin) {
  @attr() displayName;
  @attr('moment') createdAt;
  @attr('boolean') isNewForClient;

  get isUnread() {
    return !this.opened && this.isNewForClient;
  }

  opened = false;
}
