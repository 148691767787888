import EmberRouter from '@ember/routing/router';
import classic from 'ember-classic-decorator';
import config from './config/environment';

@classic
class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  function request() {
    this.route('clinician');
    this.route('service');
    this.route('location');
    this.route('date');
    this.route('channel-uploads');
    this.route('channel-fields');
    this.route('client-type');
    this.route('message');
    this.route('information', function () {
      this.route('review');
    });
    this.route('confirmation');
    this.route('cancelled');
    this.route('unavailable');
  }

  this.route('widget', function () {
    request.apply(this);
  });

  this.route('contact-widget');

  this.route('messages');
  this.route('client-selection');

  this.route('client-attendance', { path: '/client-attendance/:token' });

  this.route('sign-in', function () {
    this.route('pin', function () {
      this.route('verify', { path: '' });
    });
    this.route('token', { path: '' }, function () {
      this.route('request', { path: '' }); // eslint-disable-line ember/no-shadow-route-definition
      this.route('verify', { path: 'token' });
    });
  });

  this.route('site', { path: '' }, function () {
    this.route('home');
    this.route('license-agreement');
    this.route('legal-agreements');
    this.route('request', function () {
      request.apply(this);
    });
    this.route('appointments', function () {
      this.route('upcoming', { path: '/' });
      this.route('requested');
    });
    this.route('documents', function () {
      this.route('index', { path: '/' });
      this.route('next');
      this.route('show', { path: '/:id' });
    });
    this.route('billing', function () {
      this.route('index', { path: '/' });
      this.route('invoices');
      this.route('receipts');
      this.route('statements');
      this.route('superbills');
      this.route('payment-methods');
      this.route('payment-history');
      this.route('out-of-network');
      this.route('insurance-claims');
    });
  });

  // eslint-disable-next-line ember/no-shadow-route-definition
  this.route('home', { path: '/' }, function () {
    // eslint-disable-next-line ember/no-shadow-route-definition
    this.route('legacy-home', { path: '/home' });
    this.route('legacy-rails', { path: '/client_portal' }, function () {
      this.route('legacy', { path: '/*path' });
    });
  });

  this.route('not-found', { path: '/*path' });
  this.route('session-timeout');
});

export default Router;
